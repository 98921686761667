import React, { useState } from 'react';
import './AuthPage.css';
import apiClient from '../api/axiosConfig';
import { API } from '../api/endpoints'; // Adjust this to point to your auth endpoints

const AuthPage = () => {
    const [isRegistering, setIsRegistering] = useState(false);
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
    });
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const toggleForm = () => {
        setIsRegistering(!isRegistering);
        setMessage('');
        setError('');
        setFormData({ username: '', email: '', password: '' });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('');
        setError('');

        try {
            const endpoint = isRegistering ? API.REGISTER : API.LOGIN; // Adjust API endpoints
            const response = await apiClient.post(endpoint, formData);

            setMessage(isRegistering ? 'Registration successful!' : 'Login successful!');
            setFormData({ username: '', email: '', password: '' });

            // Handle redirection or token storage if login is successful
            if (!isRegistering) {
                localStorage.setItem('token', response.data.token);
                window.location.href = '/dashboard'; // Adjust this to your desired redirect path
            }
        } catch (err) {
            setError(err.response?.data?.message || 'An error occurred. Please try again.');
        }
    };

    return (
        <div className="auth-container">
            <h2>{isRegistering ? 'Register' : 'Login'}</h2>
            <form onSubmit={handleSubmit}>
                {isRegistering && (
                    <div className="form-group">
                        <label htmlFor="username">Username</label>
                        <input
                            type="text"
                            id="username"
                            name="username"
                            value={formData.username}
                            onChange={handleChange}
                            required
                        />
                    </div>
                )}
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                    />
                </div>
                <button type="submit" className="submit-btn">
                    {isRegistering ? 'Register' : 'Login'}
                </button>
            </form>
            {message && <p className="success-message">{message}</p>}
            {error && <p className="error-message">{error}</p>}
            <button onClick={toggleForm} className="toggle-btn">
                {isRegistering ? 'Already have an account? Login' : "Don't have an account? Register"}
            </button>
        </div>
    );
};

export default AuthPage;
