import React, { useEffect, useState } from 'react';
import './Banner.css';
import apiClient from '../api/axiosConfig';
import { API } from '../api/endpoints'; // Ensure this points to your image API endpoint
import Error from './Error';
import Loader from './Loader';

const Banner = () => {
    const [banners, setBanners] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBanners = async () => {
            try {
                const response = await apiClient.get(API.BANNERS); // Adjust API.IMAGES to match your endpoint
                setBanners(response.data);
            } catch (err) {
                setError(err.response?.data?.message || err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchBanners();
    }, []);

    if (loading) {
        return <Loader />;
    }

    if (error) {
        return <Error message={error} />;
    }

    return (
        <div className="banner-container">
            {banners.length > 0 ? (
                banners.map((banner) => (
                    <div key={banner.id} className="banner-image">
                        <img src={banner.image} alt={`Kartinkf ${banner.id}`} loading="lazy" />
                    </div>
                ))
            ) : (
                <p>No images available.</p>
            )}
        </div>
    );
};

export default Banner;
