import React from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import AppRouter from './Router';

const App = () => {
  return (
    <div className="app">
      <Navbar />
      <main className="content">
        <AppRouter />
      </main>
      <Footer />
    </div>
  );
};

export default App;
