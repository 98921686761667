import React from 'react';
import { Link } from 'react-router-dom'; // For navigation if using React Router
import './Navbar.css'; // Assuming you have a CSS file for styling

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="container">
        {/* Logo Section */}
        <div className="logo">
          <img src="" alt="KS Motors Logo" />
          <Link to="/" className='site-name'><h1>ks-motors.kz</h1></Link>
        </div>

        {/* Navigation Links */}
        <div className="nav-links">
        <Link to="/auth">Профиль</Link>
          <Link to="/orders">Заказы</Link>
          <Link to="/balance">Баланс<div style={{textAlign: 'center'}}>0 тг</div></Link>
          <Link to="/cart">Корзина</Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
