export const API = {
    REGISTER: '/customer/register', // Adjust based on your backend
    LOGIN: '/customer/login', // Adjust based on your backend
    LOGOUT: '/customer/logout',
    PRODUCTS: '/autopart/parts/',
    PRODUCT_DETAILS: (id) => `/products/${id}/`,
    BANNERS: '/autopart/banner/',
    CART: '/cart/',
    ORDER: '/order/',
  };
  