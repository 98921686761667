import React, { useState, useEffect } from 'react';
import apiClient from '../api/axiosConfig';
import { API } from '../api/endpoints';
import Loader from '../components/Loader';

const ProductListPage = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await apiClient.get(API.PRODUCTS);
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  if (loading) return <Loader />;

  return (
    <div className="product-list">
      <h1>Products</h1>
      <ul>
        {products.map((product) => (
          <li key={product.id}>
            <h3>{product.name}</h3>
            <p>{product.price} USD</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ProductListPage;
