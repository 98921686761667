import React from 'react';
import Banner from '../components/Banner';
import AutoParts from '../components/AutoParts';

const HomePage = () => {
  return (
    <div className="homepage">
      <Banner />
      <h1>Welcome to AutoParts Shop</h1>
      <p>Find the best autoparts for your car.</p>
      <AutoParts />
    </div>
  );
};

export default HomePage;
