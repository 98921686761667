import React, { useEffect, useState } from 'react'

import apiClient from '../api/axiosConfig'
import { API } from '../api/endpoints'
import Error from './Error'
import Loader from './Loader'
import './AutoParts.css'
import SearchBar from './SearchBar'


const AutoParts = () => {
  const [autoParts, setAutoParts] = useState([]);
  const [loading, setLoading] =useState(true);
  const [error, setError] = useState(null);
  const [filteredParts, setFilteredParts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchAutoParts = async () => {
        try {
            const response = await apiClient.get(API.PRODUCTS);
            setAutoParts(response.data);
        } catch (err) {
            setError(err.response?.data?.message || err.message);
        } finally {
            setLoading(false);
        }
    };

    fetchAutoParts();
  }, [API.PRODUCTS]);

  useEffect(() => {
    setFilteredParts(
      autoParts.filter((part) =>
        part.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, autoParts]);

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <Error message={error}/>
  }

  return (
    <div role="main">
        <h2>Auto Parts</h2>
        <SearchBar
            value={searchQuery}
            onChange={setSearchQuery}
            placeholder="Search auto parts..."
        />
        <table>
            <thead>
                <tr>
                    <th>Region</th>
                    <th>Supplier</th>
                    <th>Manufacturer</th>
                    <th>OEM</th>
                    <th>Name</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Ordering</th>
                </tr>
            </thead>
            <tbody>
                {autoParts.map((part) => (
                    <tr key={part.id}>
                        <td>{part.region_supplier}</td>
                        <td>{part.supplier}</td>
                        <td>{part.manufacturer}</td>
                        <td>{part.oem}</td>
                        <td>{part.name}</td>
                        <td>{part.quantity}</td>
                        <td>{part.price}</td>
                        <td>
                            <button className="order-button">Order</button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
  );
}


export default AutoParts;
