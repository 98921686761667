import React, { useContext } from 'react';
import CartContext from '../context/CartContext';

const CartPage = () => {
  const { cart } = useContext(CartContext);

  return (
    <div className="cart-page">
      <h1>Your Cart</h1>
      {cart.length === 0 ? (
        <p>Your cart is empty.</p>
      ) : (
        <ul>
          {cart.map((item) => (
            <li key={item.id}>
              {item.name} - {item.price} USD
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CartPage;
